/**
 *
 * Plugin for Enhanced Ecommerce
 * - 1. Product Impressions
 * - 2. Product Clicks
 * - 3. Add / Remove from Cart
 * - 4. Promotion Impressions
 * - 5. Promotion Clicks
 * What is not included here is present in global/__google_tag_manager.html
 */

(function ( $ ) {
    'use strict';

    var dataLayer = window.dataLayer || [],
        contProductImpressions = 0;

    function parseCategoryNames( categories ){
        var categoryArray = _.map( categories, function( object ){ return object.name;} );
        return categoryArray.join(' / ');
    }

    /**
     * 1. Product Impressions - Every time a product is renedered
     *
     * Required event: zg.getProductInfo.productCreated
     * Required data: element, options, product
     */
    $( document ).on( 'zg.getProductInfo.productCreated', function ( e, element, options, product ) {
        if( product.price ) {
            var categoryNames = parseCategoryNames(product.categories);
            var list;

            contProductImpressions++;
            if (window.SGL_JS_MANAGER === 'search') {
                list = 'Search Results';
            }
            else {
                list = 'Listing';
            }

            dataLayer.push({
                'ecommerce': {
                    'currencyCode': product.price.currency, // Local currency is optional.
                    'impressions': [
                        {
                            'name': product.name || '', // Name or ID is required.
                            'id': product.id || '',
                            'category': categoryNames || '',
                            'price': product.price.sell || '',
                            'brand': product.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
                            'list': list,
                            'position': contProductImpressions || ''
                        }]
                }
            });
        }
    } );

    /**
     * 2. Product Clicks
     *
     * To track the link, be sure that all link tag have the attr data-zg-role="product-link"
     */
    $( document ).on( 'click', '[data-zg-role="product-link"]', function(){

        if( this.attributes.href ) {

            var parent = $( this ).closest( '[data-zg-role="product"]' ).data( 'zg.product' );
            var productObj = parent.product;
            var categoryNames = parseCategoryNames( productObj.categories );

            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'products': [{
                            'name': productObj.name || '', // Name or ID is required.
                            'id': productObj.id || '',
                            'category': categoryNames || '',
                            'price': productObj.price.sell || ''
                        }]
                    }
                }
            });
        }
    });

    /**
     * 3. Add / Remove from Cart
     *
     * Required event: zg.product.addedToCart
     * Required data: status, productObj, dataSent
     */
    $( document ).on( 'zg.product.addedToCart', function ( e, status, productObj, dataSent ) {
        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': productObj.price.currency,
                'add': { // 'add' actionFieldObject measures.
                    'products': [{ //  adding a product to a shopping cart.
                        'name': productObj.name || '',
                        'id': productObj.id || '',
                        'price': productObj.price.sell || '',
                        'brand': productObj.brand || BRAND, // Mind to have "BRAND" variable added in global/_common_js_includes.html for adding the "Brand" property in Category page
												'category': window.JS_TRANSLATIONS['category.name'],
                        'variant': dataSent.sku || '',
                        'quantity': dataSent.quantity || ''
                    }]
                }
            }
        });

    } );
    /**
     * Required event: zg.product.removedFromCart
     * Required data: type, status, data
     */
    $( document ).on( 'zg.product.removedFromCart', function ( e, type, status, data ) {

        if( type === 'cart' ){
            // Measure the removal of a product from a shopping cart.
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'remove': { // 'remove' actionFieldObject measures.
                        'products': [{ //  removing a product to a shopping cart.
                            'id': data.product_id || '',
                            'variant': data.sku || '',
                            'quantity': data.quantity || ''
                        }]
                    }
                }
            });
        }

    } );

    /**
     * For Cart page
     * When a product is removed from cart we are submitting a form. Also when we submitting a form we should trigger zg.product.removedFromCart
     */
    $( document ).on( 'submit', '[data-zg-role="list-item-remove"]', function () {
        var $this = $(this),
            data  = $this.data();

        var dataToSend = {
            'product_id': 	data.id || 0,
            'sku': 					data.sku || 0,
            'quantity': 		data.quantity || 1
        };

        $( document ).trigger( 'zg.product.removedFromCart', ['cart', null, dataToSend] );
    } );

    /**
     * 4. Promotion Impressions
     *
     * To track the promo present in page, be sure that all promo div have the attr data-zg-role="promotion"
     */
    $( '[data-zg-role="promotion"]' ).each(function( index, element ) {

        dataLayer.push({
            'ecommerce': {
                'promoView': {
                    'promotions': [
                        {
                            'id': element.dataset.promoId || '', // Name or ID is required.
                            'name': element.dataset.promoName || '',
                            'position': element.dataset.promoPosition || ''
                        }]
                }
            }
        });
    });

    /**
     * 5. Promotion Clicks
     *
     * To track the promo link, be sure that all link tag have the attr data-zg-role="promotion-link"
     * Required attr: data-promo-id="x", data-promo-name="x", data-position='x'
     */
    $( document ).on( 'click', '[data-zg-role="promotion-link"]', function() {
        if( this.attributes.href ){
            dataLayer.push({
                'event': 'promotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [
                            {
                                'id': this.dataset.promoId || '', // Name or ID is required.
                                'name': this.dataset.promoName || '',
                                'position': this.dataset.promoPosition || ''
                            }]
                    }
                }
            });
        }
    });

    let payment_type = '';
    if($('.page_confirm')){
        $( document ).on( 'zg.selectPayment.paymentReady', function(e,paymentInfo){
            payment_type = paymentInfo;
            
        } );
        $('[data-role="syn-checkout"]').click(function(e){
            e.preventDefault();
            ///begin_checkout();
            let loc = $(this).attr('href');
            setTimeout(function(){
                location.href = loc;
            },600);
            

        });
        
        $('[data-payment-checkout="payment"]').click(function(e){
             e.preventDefault();
             e.stopPropagation();
            /*let paymentType=$(this).attr('data-payment-type');*/
        

            let paymentType="Paypal";
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            if (typeof cart_items != "undefined"){
                let _cart_items  = cart_items ?? {}
            dataLayer.push({
            event: "add_payment_info",
            ecommerce: {
                currency: SGL_JS_CURRENCY,
                value: SGL_JS_TOTAL_SUM,
                coupon: "",
                payment_type: paymentType,
                items: _cart_items ?? {}
            }
            });
            }
            let loc = $(this).attr('href');
            location.href = loc;
     
        });
        $( document ).on( 'zg.selectShipping.shippingReady',function(e,data,val) {

           dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            dataLayer.push({
            event: "add_shipping_info",
            ecommerce: {
                    currency: val.currency,
                    value: val.currency,
                    coupon: "",
                    shipping_tier: val.name
                    //items: cart_items
                }
            }); 
                
            
        });
    }

}( jQuery ));
